import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Inputs from "components/form/inputs";

import Dropdowns, { closeDropdown } from "components/dropdowns";

import { useDispatch, useSelector } from "react-redux";

import IconSearch from "assets/icons/search-normal.svg";

import Quad from 'assets/icons/Quad.svg'
import Triple from 'assets/icons/Triple.svg'
import Double from 'assets/icons/Double.svg'
import Single from 'assets/icons/Single.svg'

import Scroll from "components/scroll";
import Badge from "components/badge";

import { getRoomPrices } from "utils/api/paket";
import { handleUpdateJamaahOrderAPI } from 'utils/api/booking'

// import './style.css'
import { IDRFormater, toCapital } from "utils/helpers";

import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import { handleGetDataJamaah, handleGetDetailOrder } from 'stores/actions/booking'

import Switch from "components/form/switch";
import { useFormik } from "formik";

import * as Yup from 'yup';
import useDebounce from "utils/helpers/useDebounce";
import Buttons from "components/buttons";

const EditRoom = ({
  jamaahData
}) => {
  const dispatch = useDispatch()
  const params = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const [startingRoomPrices, setStartingRoomPrices] = useState([])
  const [startingSelected, setStartingSelected] = useState({})
  const [tempStartingRoomPrices, setTempStartingRoomPrices] = useState([])

  const [startingLabel, setStartingLabel] = useState('Pilih Starting')
  const [roomPriceLabel, setRoomPriceLabel] = useState('Pilih Harga Kamar')
  const [keywordStarting, setKeywordStarting] = useState("")
  const searchStartingDebounce = useDebounce(keywordStarting, 1000)

  const { bookingDetail } = useSelector((state) => state.booking);


  const [isBusinessClass, setIsBusinessClass] = useState(false)

  const titleOptions = [
    {
      label: "Tuan",
      value: 'tn'
    },
    {
      label: "Nyonya",
      value: 'ny'
    },
    {
      label: "Nona",
      value: 'nn'
    }
  ]  

  const handleFillOptions = (value) => {
    const title = titleOptions.find((item) => item.value === value.toLowerCase())

    return [title]
  }

  useEffect(() => {
    if(bookingDetail.paket.id_paket){
      handleRoomPrices()
    }
  }, [bookingDetail.paket.id_paket])

  useEffect(() => {
    if(jamaahData) {
      jamaahFormik.setFieldValue(`jamaah_name`, jamaahData.jamaah_name)
      jamaahFormik.setFieldValue(`jamaah_identity_number`, jamaahData.jamaah_identity_number)
      jamaahFormik.setFieldValue(`jamaah_title`, jamaahData.jamaah_title)
      jamaahFormik.setFieldValue(`jamaah_phone`, jamaahData.jamaah_phone)
      jamaahFormik.setFieldValue(`jamaah_email`, jamaahData.jamaah_email)

      jamaahFormik.setFieldValue('room_type.id', jamaahData.package_starting.id)
      jamaahFormik.setFieldValue('room_type.name', jamaahData.room_type)
      jamaahFormik.setFieldValue('room_type.description', jamaahData.kamar_description)
      jamaahFormik.setFieldValue('room_type.price', jamaahData.kamar_price)

      jamaahFormik.setFieldValue(`starting`, {
        name: jamaahData.name_package_starting,
        bg_color: jamaahData.package_starting.bg_color,
        text_color: jamaahData.package_starting.text_color,
        departure: jamaahData.package_starting.departure,
        arrival: jamaahData.package_starting.return
      })
      setStartingLabel(jamaahData.name_package_starting)
      setStartingSelected(jamaahData.package_starting.id)

      setTimeout(() => {
        jamaahFormik.setFieldTouched(`jamaah_name`, 1)
        jamaahFormik.setFieldTouched(`jamaah_identity_number`, 1)
        jamaahFormik.setFieldTouched(`jamaah_title`, 1)
        jamaahFormik.setFieldTouched(`jamaah_phone`, 1)
        jamaahFormik.setFieldTouched(`jamaah_email`, 1)
        jamaahFormik.setFieldTouched(`room_type.id`, 1)
        jamaahFormik.setFieldTouched(`room_type.name`, 1)
        jamaahFormik.setFieldTouched(`room_type.price`, 1)
        jamaahFormik.setFieldTouched(`room_type.description`, 1)
        jamaahFormik.setFieldTouched(`starting`, 1)
      })

      jamaahData?.room_type?.includes("_bussiness") &&
      setIsBusinessClass(true)

      let typeRoomName = jamaahData?.room_type?.replace('tripple', 'triple')
      typeRoomName = toCapital(typeRoomName)

      const label = `${typeRoomName} - ${IDRFormater(jamaahData.kamar_price)}`
      setRoomPriceLabel(label)
      
    }

    
  }, [])

  useEffect(() => {
    handleSearchStarting()
  }, [searchStartingDebounce])

  const handleRoomPrices = async () => {
    const response = await getRoomPrices(bookingDetail.paket.id_paket)
    const { status, data } = response
    
    if(status == 200) {
      setStartingRoomPrices(data.data)

      setTempStartingRoomPrices(data.data)

      if(jamaahData.name_package_starting) {

        const newStartingSelected = data.data.find((item) => item.starting.name === jamaahData.name_package_starting)
        const roomName = jamaahData?.room_type?.replace('_bussiness', '')

        setStartingSelected(newStartingSelected)
       
        setStartingLabel(newStartingSelected.starting.name)

        let roomDescription;

        if(roomName === 'quad') {
          roomDescription = '4 pax/room'
        }

        if(roomName === 'tripple') {
          roomDescription = '3 pax/room'
        }

        if(roomName === 'double') {
          roomDescription = '2 pax/room'
        }

        if(roomName === 'single') {
          roomDescription = '1 pax/room'
        }

        jamaahFormik.setFieldValue(`room_type.id`, newStartingSelected.id_kamar)
        jamaahFormik.setFieldValue(`room_type.name`, roomName)
        jamaahFormik.setFieldValue(`room_type.description`, roomDescription)
        jamaahFormik.setFieldValue(`room_type.price`, jamaahData.kamar_price)

        setTimeout(() => {
          jamaahFormik.setFieldTouched(`room_type.id`, 1)
          jamaahFormik.setFieldTouched(`room_type.name`, 1)
          jamaahFormik.setFieldTouched(`room_type.descriptions`, 1)
          jamaahFormik.setFieldTouched(`room_type.price`, 1)
        })

        setStartingLabel(newStartingSelected.starting.name)

        jamaahFormik.setFieldValue(`starting`, {
          name: newStartingSelected.starting.name,
          bg_color: newStartingSelected.starting.bg_color,
          text_color: newStartingSelected.starting.text_color,
          departure: newStartingSelected.starting.departure,
          arrival: newStartingSelected.starting.return
        })

        setTimeout(() => {
          jamaahFormik.setFieldTouched(`starting`, 1)
        })

        const label = `${toCapital(roomName)} - ${IDRFormater(jamaahData.kamar_price)}`
        setRoomPriceLabel(label)
      }

    } else {
      dispatch(handleError(response.data))
    }

  }

  const handleSetStarting = (starting) => {
    setStartingSelected(starting)
       
    setStartingLabel(starting.starting.name)

    jamaahFormik.setFieldValue(`room_type.id`, null)
    jamaahFormik.setFieldValue(`room_type.name`, null)
    jamaahFormik.setFieldValue(`room_type.description`, null)
    jamaahFormik.setFieldValue(`room_type.price`, null)

    setTimeout(() => {
      jamaahFormik.setFieldTouched(`room_type.id`, 1)
      jamaahFormik.setFieldTouched(`room_type.name`, 1)
      jamaahFormik.setFieldTouched(`room_type.description`, 1)
      jamaahFormik.setFieldTouched(`room_type.price`, 1)
    })

    setRoomPriceLabel('Pilih Harga Kamar')

    closeDropdown();
  }

  const handleSetRoomPrice = (roomID, roomName, roomDescription, price) => {
    jamaahFormik.setFieldValue(`room_type.id`, roomID)
    jamaahFormik.setFieldValue(`room_type.name`, `${roomName}${isBusinessClass ? '_bussiness' : ""}`)
    jamaahFormik.setFieldValue(`room_type.description`, roomDescription)
    jamaahFormik.setFieldValue(`room_type.price`, price)

    setTimeout(() => {
      jamaahFormik.setFieldTouched(`room_type.id`, 1)
      jamaahFormik.setFieldTouched(`room_type.name`, 1)
      jamaahFormik.setFieldTouched(`room_type.description`, 1)
      jamaahFormik.setFieldTouched(`room_type.price`, 1)
    })

    jamaahFormik.setFieldValue(`starting`, {
      name: startingSelected.starting.name,
      bg_color: startingSelected.starting.bg_color,
      text_color: startingSelected.starting.text_color,
      departure: startingSelected.starting.departure,
      arrival: startingSelected.starting.return
    })

    roomName = roomName.replace('tripple', 'triple')

    const label = `${toCapital(roomName)} - ${IDRFormater(price)}`
    setRoomPriceLabel(label)
  }

  const handleChangeBusinnessClass = () => {
    setIsBusinessClass(!isBusinessClass);

    jamaahFormik.setFieldValue(`room_type.id`, null)
    jamaahFormik.setFieldValue(`room_type.name`, null)
    jamaahFormik.setFieldValue(`room_type.description`, null)
    jamaahFormik.setFieldValue(`room_type.price`, null)

    setRoomPriceLabel('Pilih Harga Kamar')
  }


  const jamaahFormik = useFormik({
    initialValues: {
      "jamaah_title": "tn",
      "jamaah_name": null,
      "jamaah_passport_number": null,
      "jamaah_identity_number": null,
      "jamaah_phone": null,
      "jamaah_email": null,
      // "user_jamaah_id": null,
      "room_type": {
          "id": null,
          "name": null,
          "description": null,
          "price": null
      },
    },
    validationSchema: Yup.object({
      "jamaah_title": Yup.string().required("Harus pilih salah satu title"),
      "jamaah_name": Yup.string().required("Nama Jamaah harus diisi"),
      "jamaah_identity_number": Yup.string().required("NIK harus diisi")
                                            .matches(/^[0-9]+$/, "NIK Harus berupa angka")
                                            .min(16, "NIK harus 16 digit")
                                            .max(16, "NIK harus 16 digit"),
      "room_type": Yup.object({
          "id": Yup.number().required("Harus pilih starting & Tipekamar"),
          "name": Yup.string().required("Harus pilih starting & Tipekamar"),
      })
    }),
  })

  const handleSearchStarting = () => {
    const keyword = keywordStarting.toLowerCase()
    
    const filterData = tempStartingRoomPrices.filter(item =>
      item.starting.name.toLowerCase().includes(keyword) || 
      item.starting.return.toLowerCase().includes(keyword) || 
      item.starting.departure.toLowerCase().includes(keyword)
    )

    setStartingRoomPrices(filterData)
  }

  const handleSaveRoom = async () => {
    setIsLoading(true)
    const response = await handleUpdateJamaahOrderAPI(params.orderNumber, jamaahData.id, jamaahFormik.values)
    setIsLoading(false)
    if(response.status === 200) {
        dispatch(handleSuccess(null, { message: "Berhasil mengupdate data kamar", code: 200 }))
        dispatch(handleGetDataJamaah(params.orderNumber))
        dispatch(handleGetDetailOrder(params.orderNumber))
    } else {
        dispatch(handleError(response.data))
    }

  }

  return (
      <>
          <div className="border p-4 rounded-tr-2xl rounded-tl-2xl">
            <div className="mb-4 mt-2"> 
              <div className="flex flex-row items-center">
                <Switch
                  checked={isBusinessClass}
                  onChange={(val) => {
                    handleChangeBusinnessClass()
                    // handleWACabang(isChecked);
                  }}
                />
                <div className="text-sm font-semibold text-[#000] ml-2 w-full">
                  Business Class
                </div>
              </div>
            </div>
            <div>
              <div className="font-semibold text-sm mb-2">Starting</div>
              <Dropdowns
                customClass={`bg-[#f2f2f2] p-4 rounded-xl flex mt-2 justify-between items-center`}
                placement="bottom"
                labelDropdown={startingLabel}
                dropdownArrow={true}
                disabled={!bookingDetail.paket.id_paket}
              >
                <div className="bg-white w-full p-4 soft-shadow rounded-2xl">
                  <div>
                    <Inputs 
                      name="searchStarting"
                      placeholder="Cari Tipe Kamar"
                      customClass="!px-3 !py-3.5 !pl-[42px] text-sm"
                      iconPosition="left"
                      icon={IconSearch}
                      onChange={(e) => setKeywordStarting(e.target.value)}
                      />
                  </div>
                  <Scroll height="350px" customClass="pr-2">
                    {startingRoomPrices && startingRoomPrices.map((room, idx) => {
                      return (
                        <>
                          <div 
                            onClick={() => handleSetStarting(room)}
                            className="p-4 cursor-pointer active:bg-[#FF9C9A] hover:bg-red-5 hover:border-red-5 rounded-2xl flex justify-between items-start border border-gray-4 mt-[18px]">
                            <div className="flex gap-11.5 justify-between w-[40%]">
                              <div>
                                <div className="text-xs text-think mb-1">
                                  Berangkat
                                </div>
                                <div className="font-semibold">
                                  {room?.starting?.departure}
                                </div>
                              </div>
                              <div>
                                <div className="text-xs text-think mb-1">
                                  Pulang
                                </div>
                                <div className="font-semibold">
                                  {room?.starting?.return}
                                </div>
                              </div>
                            </div>
                            <Badge 
                              label={room?.starting?.name}
                              backgroundColor={room?.starting?.bg_color}
                              color={room?.starting?.text_color}
                              />
                          </div>
                        </>
                      )
                    })}
                  </Scroll>
                </div>
              </Dropdowns>
            </div>
            <div className="mt-4">
              <div className="font-semibold text-sm mb-2 mt-4">Tipe Kamar</div>
              <Dropdowns
                customClass={`bg-[#f2f2f2] p-4 rounded-xl flex mt-2 justify-between items-center`}
                placement="bottom"
                labelDropdown={roomPriceLabel}
                dropdownArrow={true}
                disabled={Object.keys(startingSelected).length === 0}
                error={jamaahFormik?.touched?.paket_kamar?.id && jamaahFormik?.errors?.paket_kamar?.id}
              >
                <div className="bg-white w-full soft-shadow rounded-2xl overflow-hidden">
                  <Scroll height="350px">
                    
                    {!!startingSelected.is_active_price_quad && (
                      <div 
                        onClick={() => {
                          handleSetRoomPrice(
                              startingSelected.id_kamar, 
                              `quad`, 
                              `4 pax/room`,
                              isBusinessClass ? startingSelected.price_quad_business : startingSelected.price_quad
                          )
                          closeDropdown()
                        }}
                        className="p-4 hover:bg-red-5 border-b border-gray-4 cursor-pointer active:bg-[#FF9C9A]">
                        <div className="flex items-center gap-2 mb-2">
                          <img 
                            src={Quad}
                            />
                          <div className="font-base font-semibold">
                            Quad
                          </div>
                        </div>
                        <div className="green-money font-semibold text-xl">
                          {isBusinessClass ? IDRFormater(startingSelected.price_quad_business) : IDRFormater(startingSelected.price_quad)}
                        </div>
                      </div>
                    )}
                    
                    {!!startingSelected.is_active_price_triple && (
                      <>
                        <div 
                          onClick={() => {
                            handleSetRoomPrice(
                                startingSelected.id_kamar, 
                                `tripple`, 
                                `3 pax/room`,
                                isBusinessClass ? startingSelected.price_triple_business : startingSelected.price_triple
                            )
                            closeDropdown()
                          }}
                          className="p-4 hover:bg-red-5 border-b border-gray-4 cursor-pointer active:bg-[#FF9C9A]">
                          <div className="flex items-center gap-2 mb-2">
                            <img 
                              src={Triple}
                              />
                            <div className="font-base font-semibold">
                              Triple
                            </div>
                          </div>
                          <div className="green-money font-semibold text-xl">
                            {isBusinessClass ? IDRFormater(startingSelected.price_triple_business) : IDRFormater(startingSelected.price_triple)}
                          </div>
                        </div>
                      </>
                    )}

                    {!!startingSelected.is_active_price_double && (
                      <div 
                        onClick={() => {
                          handleSetRoomPrice(
                            startingSelected.id_kamar, 
                            `double`, 
                            `2 pax/room`,
                            isBusinessClass ? startingSelected.price_double_business : startingSelected.price_double
                          )
                          closeDropdown()
                        }}
                        className="p-4 hover:bg-red-5 border-b border-gray-4 cursor-pointer active:bg-[#FF9C9A]">
                        <div className="flex items-center gap-2 mb-2">
                          <img 
                            src={Double}
                            />
                          <div className="font-base font-semibold">
                            Double
                          </div>
                        </div>
                        <div className="green-money font-semibold text-xl">
                          {isBusinessClass ? IDRFormater(startingSelected.price_double_business) : IDRFormater(startingSelected.price_double)}
                        </div>
                      </div>
                    )}

                    {!!startingSelected.is_active_price_single && (
                      <div 
                        onClick={() => {
                          handleSetRoomPrice(
                            startingSelected.id_kamar, 
                            `single`, 
                            `1 pax/room`,
                            isBusinessClass ? startingSelected.price_single_business : startingSelected.price_single
                          )
                          closeDropdown()
                        }}
                        className="p-4 hover:bg-red-5 border-b border-gray-4 cursor-pointer active:bg-[#FF9C9A]">
                        <div className="flex items-center gap-2 mb-2">
                          <img 
                            src={Single}
                            />
                          <div className="font-base font-semibold">
                            Single
                          </div>
                        </div>
                        <div className="green-money font-semibold text-xl">
                          {isBusinessClass ? IDRFormater(startingSelected.price_single_business) : IDRFormater(startingSelected.price_single)}
                        </div>
                      </div>
                    )}
                  </Scroll>
                </div>
              </Dropdowns>
            </div>
          </div>
          <div className="border p-4 rounded-br-2xl rounded-bl-2xl border-t-0 flex justify-end"> 
              <Buttons 
                text="Simpan"
                customClass="btn btn-primary btn-large"
                onClick={handleSaveRoom}
                loading={isLoading}
                disabled={!(jamaahFormik.isValid && jamaahFormik.dirty)}
                />
          </div>
      </>
  )
}

export default EditRoom